@import url('//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css');
.ace-tm {
  background-color: #f7efef;
  color: black;
}
/* .ace_editor {

  z-index: -5;
} */
.ace-tm .ace_gutter {
  background: #dcdcdc;
  color: #333;
  z-index:inherit;
}

.ace_gutter-cell{
  pointer-events: none;
  font-family: sans;
  font-weight: 200;
  text-transform: uppercase;
 
}
.ace_gutter-cell:after {
  content:"\f054";
  font-family: 'FontAwesome';
   padding-right: 4px;
  padding-left: 4px; 
 font-size: 12px;
   pointer-events: initial;
   cursor: pointer;

}

.gutter-click-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px; /*Adjust the width as needed to cover the gutter*/
  height: 100%;
  cursor: pointer;
  z-index: 1; /* Make sure it's above the Ace Editor content */
}
.ace_line_group > div:nth-child(2),

.ace_line_group > div:nth-child(3) {

    color: lightslategray;
 

}

/*CSS for the search box */

.ace_search { /* Background color and border */
  position: fixed;
  top: 100px;
  left: 65%;
  width: 400px !important;
  
  padding: 8px 12px;
  background-color: #f7efef; 
  border: 1px solid #ccc; 
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

/* Search box input field */
.ace_search_field {
  width: 100%;
  padding: 6px 8px;
  background-color: #fff; 
  color: #333; 
  border: none;
  border-radius: 3px;
  outline: none;
}
span.ace_button{
  display: none;
}

/* Search box input field focus */
.ace_search_field:focus {
  background-color: #f7efef; 
  border: 1px solid #ccc; 
}

/* Search box button */
.ace_searchbtn {
  background-color: #fff; 
  color: #333; 
  border: none;
  padding: 6px 12px;
  margin-left: 8px;
  border-radius: 3px;
  cursor: pointer;
}

/* Search box button hover */
.ace_searchbtn:hover {
  background-color: #f7efef; 
}
 /* CSS for highlighting matched text */
  .ace-tm .ace_selected-word {
    background-color: rgb(234, 255, 0);
    color: white;
  }

  .file-loc {
    position: fixed;
    height: 20px;
    width: 100%;
    display: flex; /* Use flexbox to arrange children horizontally */
    align-items: center; /* Vertically center the children */
    justify-content: space-between; 
    background-color: #dcdcdc;
    padding: 10px; /* Add padding as needed */
  }
  
  .mouse-location {
    
    /* background-color: #f7efef; */
    color: #333;
    padding-right: 70px;
    font-size: 12px;
    font-weight: 600;
    font-style: oblique;
    padding-bottom: 17px;
  }
  
  .file-name {
    /* background-color: #333; */
    /* color: #fff; */
    /* padding: 10px; */

    position: fixed;
    color: #000000;
    padding-left: 65px;
    font-size: 15px;
    font-weight: 600;
    font-style:inherit;
    padding-bottom: 17px;
  


  }

  /* .file-content{
    padding-top: 2px;
    background-color: rgb(118, 118, 118);
    border-radius: 2px;
  } */

  .col_index{
    /* height: 50px; */
    /* position: fixed; */
    /* width: 200px; */
    width: 100%;
    background-color: #f7efef;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: hidden;
   
    /* border: 2px black; */
  }
  
  .left_index{
    width: 45px;
    /* background-color: #811818; */
    /* border: 0px solid #8d8d8d; */
    
    background-color: #b6b6b6;
    
  }
  
  .right_index{
    margin-left: 47px;
    letter-spacing: 1.4px;
    display: flex;
    flex-direction: row;
    border-bottom: 3px solid #8d8d8d;
    /* border-left: 3px solid #8d8d8d;; */
   
  }
 
  .index_head_num{
    text-align: center;
    font-weight: bold;
  }
  .index_data_num{
    font-size: 15px;
  }
  
  .indexWrapper{
    color: #fff9f9;
    /* border-right: white 0.1px solid; */
    margin-left: 1.4px;
  
  }
  
  .indexWrapper:nth-child(odd){
    /* color: #f7efef; */
    background-color: #eee8e8;
    color: black;
    /* border-right: white 0.1px solid; */
    
  }
  
  .indexWrapper:nth-child(even){
    /* color: #f7efef; */
    background-color: #fff9f9;
    color: black;
    /* border-right: white 0.1px solid; */
    
  }

.sub-editor{
  letter-spacing: 1.4px;
  font-size: 15px;
  color: #000000;;
}