*{
    margin:0;
    padding:0

}
#ace_editor{
    z-index:-5;
}
#root{
    position:fixed;
    width:100%
}