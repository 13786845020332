.navbar {
  background-color: #0078d4;
  color: white;
  /* width: 207px; */
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.navbar-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-item {
  margin: 0 10px;
  padding: 10px 10px;

}
/* .navbar-item:hover {
  background-color: #69b7f2;
  cursor: pointer;
} */

.dropdown {
  position: relative;
}

.dropdown-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.sub-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 1;
}

.sub-dropdown-content {
  margin-top: 10px;
}

.sub-dropdown-label {
  display: block;
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
}

.sub-dropdown-select,
.sub-dropdown-input {
  width: 95%;
  padding: 6px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.sub-dropdown-text {
  margin-top: 5px;
  color: #555;
}

.sub-dropdown-button {
  background-color: #0078d4;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.sub-dropdown-button:hover {
  background-color: #005a9e;
}

.record-length-button{
  margin-top: 5px;
  margin-left: 13px;
}

.save-cancel-button{
  margin-top: 10px;
}

#reload-btn {
  margin-left: 570px;
}
